/* global contactModule:true */

contactModule.controller('MainCtrl', ['$scope', '$timeout', '$location', 'FileUploader', 'HttpService', 'AttachmentsDialogService', 'FormInitializeService', 'FormErrorsService', 'GroupMeetingFormService', function ($scope, $timeout, $location, FileUploader, HttpService, AttachmentsDialogService, FormInitializeService, FormErrorsService, GroupMeetingFormService) {

    $scope.$on('$viewContentLoaded', () => {
        contactModule.contactAA.stopSpinner();
        $timeout(() => {
            contactModule.contactAA.setFocusOn('#aa-contactInfo h2');
        }, 0);
    });

    $scope.initLandingPage = function () {
        if (contactModule.showResource !== '') {
            $location.path(contactModule.showResource);
        }
    };

    $scope.initTopicSelectionForm = function () {
        if(angular.element('#formModel').data('json')) {
            $scope.formModel = angular.element('#formModel').data('json');
        }
        else {
            $scope.formModel = { 'topicInfo': { 'topic': null, 'subject': '', 'reason': '', 'locale': null } };
        }
        var topic = $scope.formModel.topicInfo.topic || '';
        $scope.formModel.subjects = contactModule.subjectsMap[topic] || [];
        $scope.formModel.reasons = contactModule.reasonsMap[topic] || [];
        
        FormInitializeService.initTopicSelectionForm($scope.formModel);
    };

    $scope.initForm = function (serviceUrl) {
        $scope.formModel = angular.element('#formModel').data().json;
        FormInitializeService.initContactForm($scope.formModel);
        const sessionLocale = AAcom.prototype.getProperty('user.locale');
        if ((sessionLocale === 'es_US' || sessionLocale === 'en_US') && $scope.formModel.contactInfo.addressInfo.country === '') {
            $j('select[name="contactInfo.addressInfo.country"] option[value="US"]').attr('selected', 'selected');
            $scope.formModel.contactInfo.addressInfo.country = $j('select[name="contactInfo.addressInfo.country"]').find('option:selected').val();
        }
        if ($scope.formModel.groupMeetingTravelInfo) {
            $scope.newEvent = {};
            angular.copy($scope.formModel.groupMeetingTravelInfo.events[0], $scope.newEvent);
        }
    };

    $scope.$on('topicChanged', () => {
        $timeout(() => {
           angular.element(contactModule.subjectSelector).trigger('change');
           angular.element(contactModule.reasonSelector).trigger('change');
        }, 0);
    });

    $scope.topicChange = function (topicInfo) {
        var topic = topicInfo.topic || '';
        $scope.formModel.subjects = contactModule.subjectsMap[topic] || [];
        $scope.formModel.reasons = contactModule.reasonsMap[topic] || [];
       
        FormInitializeService.handleTopicChange(topicInfo);
    };

    $scope.subjectChange = function (topicInfo, subjectInfo) {
        $scope.formModel = angular.element('#formModel').data().json;
        FormInitializeService.handleSubjectChange(topicInfo, subjectInfo);
        // when subject changes, determine if there is a self-service message in play
        // and then update a model property that controls the visibility of the
        // Next button.
    };

    $scope.clickUpload = function() {
        setTimeout(() => {
            angular.element('#file-upload').trigger('click');
        }, 0);
    };
    $scope.submitContactForm = function ($event) {
        $event.preventDefault();

        if (($scope.formModel.topicInfo.subject !== '' && ($scope.formModel.topicInfo.topic == 'BG' || $scope.formModel.topicInfo.topic == 'CR')) || $scope.formModel.topicInfo.subject == 'SVAN') {
	            $scope.formModel.topicInfo.topicDesc = $j('select[name="topicInfo.topic"]').find('option:selected').text();
	            $scope.formModel.topicInfo.subjectDesc = $j('select[name="topicInfo.subject"]').find('option:selected').text();

	            if ($scope.formModel.topicInfo.topic === 'CR') {
                    $scope.formModel.topicInfo.reasonDesc = $j('select[name="topicInfo.reason"]').find('option:selected').text();
                }

            	if (($scope.uploader.queue === undefined || $scope.uploader.queue.length == 0) && $j('#attachmentDialogPopupFlag').val() == 'true') {
                	AttachmentsDialogService.showNoAttachmentsDialog($scope.formModel.topicInfo.subject);

                	return;
            }
                	if (areFilesStillUploading()) {
                AttachmentsDialogService.showFilesStillUploadingDialog();

                return;
                	}
                	const filesKeyList = [];
                	const filesNameList = [];
                	if (!doOverallFileLimitValidation() || !doOverallFileSizeValidation()) {
        		        $timeout(() => {
        		            contactModule.contactAA.setFocusOn('#attachmentErrorSummary:visible div');
        		        }, 0);

            			return;
                	}
                	let errorFlag = false;
                	for (let i = 0; i < $scope.uploader.queue.length; i++) {
                		if ($scope.uploader.queue[i].isError) {
                			errorFlag = true;
            		        $timeout(() => {
            		            contactModule.contactAA.setFocusOn('#attachmentErrorSummary:visible div');
            		        }, 0);

                			return;
                		}
                		filesKeyList.push($scope.uploader.queue[i].fileKey);
                		filesNameList.push($scope.uploader.queue[i].file.name);
                	}
                	uploader.hasFieldErrors = false;
                	uploader.hasLimitExceedError = false;
                	uploader.hasSizeExceedError = false;
                	uploader.hasUserError = false;
                	uploader.hasServerError = false;
               		uploader.tier2ErrorList = [];
            		$scope.formModel.fileKeys = filesKeyList;
            		$scope.formModel.fileNames = filesNameList;
        }
        contactModule.contactAA.startSpinner();
        $j('input[name$=Airport]').trigger('change');
        const _form = $event.target;
        FormErrorsService.clearErrors(`#${ _form.id}`);
        $scope.errors = {};
        if (_form.action.indexOf('/contact/forms/topic-selection') === -1 && $scope.formModel.topicInfo.subject === 'SVAN' && $scope.formModel.topicInfo.topic === "DA") {
            $scope.formModel.contactInfo.emailPhoneForm.primaryPhone = {};
            $scope.formModel.contactInfo.emailPhoneForm.secondaryPhone = {};
            $scope.formModel.contactInfo.emailPhoneForm.primaryPhone.number = $scope.formModel.contactInfo.emailPhoneForm.phones[0].number;
            $scope.formModel.contactInfo.emailPhoneForm.primaryPhone.countryCode = $scope.formModel.contactInfo.emailPhoneForm.phones[0].countryCode;
            $scope.formModel.contactInfo.emailPhoneForm.primaryPhone.type = '';
            $scope.formModel.contactInfo.emailPhoneForm.primaryPhone.primary = false;
            $scope.formModel.contactInfo.emailPhoneForm.primaryPhone.dialingCode = '';
            $scope.formModel.contactInfo.emailPhoneForm.secondaryPhone.number = $scope.formModel.contactInfo.emailPhoneForm.phones[1].number;
            $scope.formModel.contactInfo.emailPhoneForm.secondaryPhone.countryCode = $scope.formModel.contactInfo.emailPhoneForm.phones[1].countryCode;
            $scope.formModel.contactInfo.emailPhoneForm.secondaryPhone.type = '';
            $scope.formModel.contactInfo.emailPhoneForm.secondaryPhone.primary = false;
            $scope.formModel.contactInfo.emailPhoneForm.secondaryPhone.dialingCode = '';
        }
        if ((_form.action.indexOf('/contact/forms/topic-selection') === -1 && $scope.formModel.topicInfo.subject === 'SVAN' && $scope.formModel.topicInfo.topic === "DA") || $scope.formModel.topicInfo.topic === "CR")
        {
            $scope.formModel.locale = AAcom.prototype.getProperty('user.locale');
        }

        let options = {
            data: $scope.formModel,
            url: _form.action,
            method: _form.method,
            formId: _form.id
        };
        if (options.url.indexOf('/contact/forms/topic-selection') === -1 && $scope.formModel.topicInfo.subject != 'SVAN' && $scope.formModel.topicInfo.topic === "DA")
        {
            options.url = '/contact/disabilityAssistance';
        }
        if (options.url.indexOf('/contact/forms/topic-selection') > -1) {
            options.data.migratedTopics = angular.element('#migratedTopics').attr('data-migratedTopics');
        }

        createAttachmentAnalytics();
        HttpService.submitForm(options).then(response => {
            handleSuccessResponse(_form.id, response);
        }, (error, status) => {
            	if (error.data != null && error.data.errors) {
                $scope.errors = error.data.errors;
                const newErrors = error.data.errors;
                const fieldErrorsWithMessages = error.data.errors.fieldErrors;
                const pageErrorCodes = [];
                for (const key in error.data.errors.fieldErrors) {
                    let value = error.data.errors.fieldErrors[key];
                    if (value.startsWith('ERRCODE')) {
                        	pageErrorCodes.push(value);
                        	value = contactModule[value];
                    }
                    fieldErrorsWithMessages[key] = value;
                }
                newErrors.fieldErrors = fieldErrorsWithMessages;
                FormErrorsService.handleFormErrors(_form.id, newErrors);
                pageErrorCodes.sort();
            		const allErrors = pageErrorCodes.join(',');
            		reportErrorsToWebAnalytics('Subject Select', allErrors);
            } else {
                $scope.errors.hasTier1Error = true;
                reportErrorsToWebAnalytics('Subject Select', 'ERRCODE858');
                FormErrorsService.handleServerError();
            }
        });
    };


    if (!String.prototype.startsWith) {
        	  String.prototype.startsWith = function(searchString, position) {
        	    position = position || 0;

        	    return this.indexOf(searchString, position) === position;
        	  };
        	}

    var areFilesStillUploading = function() {
        for (let i = 0; i < uploader.queue.length; i++) {
            if (!uploader.queue[i].isUploaded) {
                return true;
            }
		    }

		    return false;
    };

    const failedErrorCount = function() {
        let errorCount = 0;
        for (let i = 0; i < uploader.queue.length; i++) {
            if (uploader.queue[i].isError) {
                errorCount++;
            }
		    }

		    return errorCount;
    };

    var reportErrorsToWebAnalytics = function (pageName, errorString) {
        	const webAnalyticsData = [];
        	webAnalyticsData.push(new KeyValueObject('channel', 'Contact'));
    		webAnalyticsData.push(new KeyValueObject('page_name', pageName));
    		webAnalyticsData.push(new KeyValueObject('error_id', errorString));
    		waTealiumUtils.reportEvent(webAnalyticsData, 'view');
    };

    const reportErrorsToWebAnalyticsAsLink = function (pageName, errorString) {
        	const webAnalyticsData = [];
        	webAnalyticsData.push(new KeyValueObject('channel', 'Contact'));
    		webAnalyticsData.push(new KeyValueObject('page_name', pageName));
    		webAnalyticsData.push(new KeyValueObject('error_id', errorString));
    		waTealiumUtils.reportEvent(webAnalyticsData, 'click');
    };

    var createAttachmentAnalytics = function() {
        	let analysticsData = '{"contact_attachments":"';
        	const fileData = [];
        	for (let i = 0; i < uploader.queue.length; i++) {
        		let extn = 'JPG';
        		if (uploader.queue[i].file.name.indexOf('.') > -1) {
        			extn = uploader.queue[i].file.name.split('.').pop().toUpperCase();
        		}
            	const fileSizeinKB = Math.ceil(uploader.queue[i].file.size / 1024);
            	fileData.push(`${extn }|${ fileSizeinKB }KB`);
        	}
        	analysticsData += `${fileData.join(',') }"}`;
        	sessionStorage.setItem('utag_data_session', analysticsData);
    };

    const doOverallFileValidations = function() {
        	if (!doOverallFileLimitValidation()) {
            	uploader.hasFieldErrors = true;
            	if (!uploader.hasLimitExceedError) {
            		uploader.tier2ErrorList.push(contactModule.totalLimitExceedError);
            	}
            	uploader.hasLimitExceedError = true;
        	}
        	if (!doOverallFileSizeValidation()) {
        		uploader.hasFieldErrors = true;
        		if (!uploader.hasSizeExceedError) {
            		uploader.tier2ErrorList.push(contactModule.totalSizeExceedError);
            	}
        		uploader.hasSizeExceedError = true;
        	}
    };
    var doOverallFileSizeValidation = function() {
        	let totalFileSize = 0;
        	for (let i = 0; i < uploader.queue.length; i++) {
            totalFileSize += uploader.queue[i].file.size;
        	}
        	if (totalFileSize > 20971520) {
        		return false;
        	}

        	return true;
    };
    var doOverallFileLimitValidation = function() {
        	if (uploader.queue.length > 20) {
        		return false;
        	}

        	return true;
    };

    $scope.addEvent = function () {
        const { events } = $scope.formModel.groupMeetingTravelInfo;
        if (events.length > 5) {
            return;
        }
        $timeout(() => {
            events.push($j.extend(true, {}, $scope.newEvent));
            $scope.$digest();

            const lastEventTitle = `#eventTitle${ events.length - 1}`;
            contactModule.contactAA.setFocusOn($j(lastEventTitle));
        });
    };

    $scope.deleteFile = function(item) {
        	const { fileKey } = item;
        	item.remove();
        	uploader.counter = Math.round((uploader.progress / 100) * uploader.queue.length);
        	uploader.errorCount = failedErrorCount();
        	const options = {
        		url: `${$j('#deleteUrl').val() }/${ fileKey}`,
            method: 'DELETE'
        };
        HttpService.submitForm(options);
    };

    $scope.deleteEvent = function () {
        const { events } = $scope.formModel.groupMeetingTravelInfo;
        GroupMeetingFormService.showDeleteEventDialog(events);
    };

    $scope.redirectToLogin = function () {
        const url = angular.element('#redirectToLogin').attr('data-url');
        window.location = url;
    };

    var handleSuccessResponse = function (formID, response) {
        if (response.data.success && response.data.redirect) {
            window.location = response.data.viewName;
            contactModule.contactAA.stopSpinner();
        } else if (response.data.success && !response.data.redirect) {
            $location.path(response.data.viewName);
        } else if (response.data.errors) {
            $scope.errors = response.data.errors;
            const newErrors = response.data.errors;
            const fieldErrorsWithMessages = response.data.errors.fieldErrors;
            const pageErrorCodes = [];
        		const webAnalyticsData = [];
        		for (const key in response.data.errors.fieldErrors) {
                let value = response.data.errors.fieldErrors[key];
                if (value.startsWith('ERRCODE')) {
                    	pageErrorCodes.push(value);
                    	value = contactModule[value];
                }
                fieldErrorsWithMessages[key] = value;
            }
            newErrors.fieldErrors = fieldErrorsWithMessages;
            FormErrorsService.handleFormErrors(formID, newErrors);
            pageErrorCodes.sort();
        		const allErrors = pageErrorCodes.join(',');
        		reportErrorsToWebAnalytics('Subject Select', allErrors);
        } else {
            $scope.errors.hasTier1Error = true;
            reportErrorsToWebAnalytics('Subject Select', 'ERRCODE858');
            FormErrorsService.handleServerError();
        }
    };

    var uploader = $scope.uploader = new FileUploader({
        url: $j('#uploadUrl').val(),
        autoUpload: true,
        queueLimit: 30,
        counter: 0,
        errorCount: 0
    });
        // FILTERS
        // a sync filter
    uploader.filters.push({
        name: 'syncFilter',
        fn(item /* {File|FileLikeObject}*/, options) {
            return this.queue.length < 30;
        }
    });

    // an async filter
    uploader.filters.push({
        name: 'asyncFilter',
        fn(item /* {File|FileLikeObject}*/, options, deferred) {
            setTimeout(deferred.resolve, 1e3);
        }
    });

    // CALLBACKS

    uploader.onWhenAddingFileFailed = function(item /* {File|FileLikeObject}*/, filter, options) {
    };
    uploader.onAfterAddingFile = function(item) {
        	item.isWaiting = true;
        $timeout(() => {
            	contactModule.contactAA.setFocusOn('#attachButton');
        }, 0);
    };
    uploader.onAfterAddingAll = function(addedFileItems) {
    };
    uploader.onBeforeUploadItem = function(item) {
        	if (angular.isUndefined(uploader.tier2ErrorList)) {
        		uploader.tier2ErrorList = [];
        	}
        	item.fileIcon = 'icon-doc icon-align-bottom';
        if (item.file.name.indexOf('.') > -1) {
            	const extn = item.file.name.split('.').pop().toUpperCase();
	            const validImgExtn = ['JPG', 'TIF', 'BMP', 'GIF', 'PNG', 'JPEG', 'JPE', 'JFIF', 'TIFF'];
	            const validDocExtn = ['DOC', 'DOCX', 'PDF', 'XLSX', 'XLS', 'RTF', 'TXT'];
	            const isValidImgIndex = validImgExtn.indexOf(extn);
	            const isValidDocIndex = validDocExtn.indexOf(extn);
	            if (isValidImgIndex > -1) {
	            	item.fileIcon = 'icon-image icon-align-text-bottom';
	            }
	            if (isValidImgIndex < 0 && isValidDocIndex < 0) {
	            	item.isWrongFileType = true;
	            	item.message = contactModule.ERRCODE1457;
	            	item.cancel();
	            	uploader.hasFieldErrors = true;
	            	if (!uploader.hasUserError) {
	            		uploader.tier2ErrorList.push(contactModule.userError);
	            	}
	        		uploader.hasUserError = true;
	        		reportErrorsToWebAnalyticsAsLink('Subject Select', 'ERRCODE1457');
	            }
        }
        if (item.file.size > 7340032) {
            	item.isWrongSize = true;
            	item.message = contactModule.ERRCODE1458;
            	item.cancel();
            	uploader.hasFieldErrors = true;
            	if (!uploader.hasUserError) {
            		uploader.tier2ErrorList.push(contactModule.userError);
            	}
        		uploader.hasUserError = true;
        		reportErrorsToWebAnalyticsAsLink('Subject Select', 'ERRCODE1458');
        }
        	item.isWaiting = false;
    };
    uploader.onProgressItem = function(item, progress) {
        	this.counter = Math.round((this.progress / 100) * this.queue.length);
    };
    uploader.onProgressAll = function(progress) {
    };
    uploader.onSuccessItem = function(item, response, status, headers) {
    };
    uploader.onErrorItem = function(item, response, status, headers) {
        	if (response.startsWith('ERRCODE')) {
        		uploader.hasFieldErrors = true;
        		if (!uploader.hasUserError) {
            		uploader.tier2ErrorList.push(contactModule.userError);
            	}
        		uploader.hasUserError = true;
        		item.message = contactModule[response];
        		reportErrorsToWebAnalyticsAsLink('Subject Select', response);
        	} else {
        		uploader.hasFieldErrors = true;
        		if (!uploader.hasServerError) {
            		uploader.tier2ErrorList.push(contactModule.serverError);
            	}
        		uploader.hasServerError = true;
        		item.message = contactModule.ERRCODE1467;
        		reportErrorsToWebAnalyticsAsLink('Subject Select', 'ERRCODE1467');
        	}
    };
    uploader.onCancelItem = function(item, response, status, headers) {
        	item.isUploaded = true;
    };
    uploader.onCompleteItem = function(item, response, status, headers) {
        	item.fileKey = response;
        	item.isError = (item.isWrongFileType || item.isWrongSize || item.isError);
    };
    uploader.onCompleteAll = function() {
        	doOverallFileValidations();
        	uploader.errorCount = failedErrorCount();
    };
}]);
